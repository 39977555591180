import { CreateCartParams, GetCartParams, UpdateCartParams } from "./interfaces/carts.interface";
import { request } from "./utils";

const CreateCart = (data: CreateCartParams) => {
  return request("carts", "post", {data})
}

const UpdateCart = (data: UpdateCartParams) =>  {
  const cartId = data.id;
  const dataCopy = {...data};
  delete dataCopy.id;

  return request(`carts/${cartId}`, 'put', { data: dataCopy })
}

const GetCart = (data: GetCartParams) => {
  return request(`carts/${data.id}`, "get")
}


export {
  CreateCart,
  UpdateCart,
  GetCart
}